import React, { Fragment } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { PaginationHeader } from 'src/components/pagination';
import { StyledButton } from 'src/components/buttons';
import { ContentfulImplicitAssociationAssessment } from 'types/graphql-types';
import { CustomLayout } from 'src/components/layout';
import { IFooterProps, IHeaderProps, headerTypes } from 'src/components/layout/custom-layout';
import { ButtonType } from 'src/components/buttons/styled-button';
import { graphql, useStaticQuery } from 'gatsby';
import { useLanguageDataGetter } from 'src/hooks/useLanguageData';
/**
 * Weight Bias Introduction page
 * This is the introduction page for the weight bias section 
 * 
*/


const useStyles = makeStyles(theme => ({
    root: {
        fontFamily: "Roboto",
        fontSize: "0.875rem",
    },
    content: {
        // margin: "1.875rem 0",
        fontFamily: "Roboto Condensed",
        fontSize: "1.1875rem",
    },
    tutorialImage: {
        width: "100%",
        margin: "0.3125rem",
    },

    table: { width: "100%", fontFamily: "Roboto Condensed", borderCollapse: "collapse", borderSpacing: 0, },
    thead: {},
    theadth: { padding: "0.4375rem", fontSize: "1.1875rem", fontWeight: 400, textAlign: "left", background: "#f5f5f5", border: "0.125rem solid #707070", },
    tbody: {},
    trow: {},
    th: { padding: "0.4375rem", fontSize: "1.1875rem", fontWeight: 400, textAlign: "left", verticalAlign: "top", borderBottom: "0.125rem solid #999", borderRight: "0.125rem solid #707070", },
    td: { padding: "0.4375rem", verticalAlign: "top", borderBottom: "0.125rem solid #707070", },
}))

interface IProps {
    data: ContentfulImplicitAssociationAssessment
    handleNextSurveyStep: () => void
}

const ImplicitBiasIntroductionPage: React.FC<IProps> = ({ handleNextSurveyStep, data }) => {
    const classes = useStyles();
    const { firstAssociationPair, secondAssociationPair, secondCategoryTutorialImage, firstCategoryTutorialImage } = data;
    const dataQuery = useStaticQuery<any>(graphql`
        query ContentfulImplicitBiasIntroduction {
            allContentfulImplicitBiasIntroduction {
                edges {
                  node {
                    tableItemsLabel
                    tableCategoryLabel
                    node_locale
                    footer {
                      footer
                    }
                    header {
                      header
                    }
                  }
                }
              }
        }  
    `)
    const introductionLangData = useLanguageDataGetter<any>(dataQuery.allContentfulImplicitBiasIntroduction);
    const introductionContent = introductionLangData.node;

    const { footer, header, tableCategoryLabel, tableItemsLabel } = introductionContent;
    const footerProps: IFooterProps = {
        submitButton: {
            onClickHandler: handleNextSurveyStep,
            type: ButtonType.CONTINUE,
            isDisabled: false
        },
        allowSkip: false
    }

    const headerProps: IHeaderProps = {
        type: headerTypes.contentPagination,
        title: "Instructions",
        step: 0,
        allSteps: 0
    }

    return (
        <CustomLayout
            headerProps={headerProps}
            footerProps={footerProps}
        >
            <Typography paragraph className={classes.content}>{ header.header }</Typography>
            <table className={classes.table}>
                <thead className={classes.thead}>
                    <tr className={classes.trow}>
                        <th className={classes.theadth}>{ tableCategoryLabel }</th>
                        <th className={classes.theadth}>{ tableItemsLabel }</th>
                    </tr>
                </thead>
                <tbody className={classes.tbody}>
                    {secondAssociationPair?.map((pair, index) => {
                        const options = pair?.list?.map(x => x?.text);
                        return (
                            <tr key={index} className={classes.trow}>
                                <th className={classes.th}>{pair?.text}</th>
                                <td className={classes.td}>{options && options?.join(", ")}</td>
                            </tr>
                        )
                    })}
                    {firstAssociationPair && firstAssociationPair[0] &&
                        <tr className={classes.trow}>
                            <th className={classes.th}>{firstAssociationPair[0]?.text}</th>
                            <td className={classes.td}>
                                <img className={classes.tutorialImage} src={firstCategoryTutorialImage?.file?.url!} alt="image" />
                            </td>
                        </tr>

                    }
                    {firstAssociationPair && firstAssociationPair[1] &&
                        <tr className={classes.trow}>
                            <th className={classes.th}>{firstAssociationPair[1]?.text}</th>
                            <td className={classes.td}>
                                <img className={classes.tutorialImage} src={secondCategoryTutorialImage?.file?.url!} alt="image" />
                            </td>
                        </tr>
                    }
                </tbody>
            </table>

            <Typography paragraph className={classes.content}>{ footer.footer }</Typography>
        </CustomLayout>
    )
}

export default ImplicitBiasIntroductionPage;