import React, { Fragment, useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { ImplicitBiasMultipleAnswers, ImplicitBiasSingleAnswer } from '.';
import { useSharedContent } from 'src/hooks/customGraphqlHooks';
import { useMediaQuery } from 'react-responsive';

const useStyles = (props: any) => makeStyles(theme => ({
    buttonsWrapper: {
        display: "flex",
        justifyContent: "space-between",
        margin: "-5px",
    },
    button: {
        margin: "0px 5px",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "6.75rem",
        height: "10.75rem",
        padding: "0.625rem",
        color: "#fff",
        fontFamily: "'Open Sans', Arial, Helvetica, sans-serif",
        fontSize: "1.0625rem",
        fontWeight: 700,
        lineHeight: "1.2",
        textAlign: "center",
        background: theme.palette.primary.main,
        border: "0.125rem solid #AF4103",
        borderRadius: "0.4375rem",
        boxShadow: "0.25rem 0.25rem 0.375rem rgba(0,0,0,0.16)",

        '&:after': {
            content: "''",
            position: "absolute",
            display: "block",
            top: props.isDesktop ? "-13.5rem" : "-5.5rem",
            width: props.isDesktop ? "5rem" : "20rem",
            height: "50rem",
            background: "no-repeat center center",
            backgroundSize: "contain",
        },

        '&:first-child:after': {
            right: "1rem",
            backgroundImage: `url(${props.leftImage})`,
        },

        '&:last-child:after': {
            left: "1rem",
            backgroundImage: `url(${props.rightImage})`,
        },

        '&:before': {
            'z-index': 1,
            content: "''",
            position: "absolute",
            display: "block",
            top: "3.8rem",
            width: "20rem",
            height: "50rem",
            background: "no-repeat center center",
            backgroundSize: "contain",
        },

        '&:first-child:before': {
            right: "2.8rem",
            backgroundImage: `url(${props.leftBonusImage})`,
        },

        '&:last-child:before': {
            left: "2.8rem",
            backgroundImage: `url(${props.rightBonusImage})`,
        },
    },
    seperator: {
        display: "block",
        margin: "0.625rem auto",
    },
    primaryAnswer: {
        display: "block",
    },
    secondaryAnswer: {
        display: "inline-block",
    },
}))

interface IProps {
    leftAnswers: string[]
    rightAnswers: string[]
    isMultipleAnswers: boolean
}

const ImplicitBiasAnswerHeader: React.FC<IProps> = ({ isMultipleAnswers, leftAnswers, rightAnswers }) => {
    const isDesktop = useMediaQuery({
        query: '(min-device-width: 1280px)'
    });

    const sharedContent = useSharedContent();

    const leftImage = isDesktop
        ? sharedContent?.implicitAssociationTutorialDKey?.file?.url 
        : sharedContent?.implicitAssociationTutorialLeftHand?.file?.url;

    const rightImage = isDesktop
        ? sharedContent?.implicitAssociationTutorialKKey?.file?.url 
        : sharedContent?.implicitAssociationTutorialRightHand?.file?.url;

    const leftBonusImage = isDesktop
        ? sharedContent?.implicitAssociationTutorialLeftFinger?.file?.url
        : undefined;

    const rightBonusImage = isDesktop
        ? sharedContent?.implicitAssociationTutorialRightFinger?.file?.url
        : undefined;

    const classes = useStyles({ 
        leftImage,
        rightImage,
        leftBonusImage,
        rightBonusImage,
        isDesktop
    })();

    return useMemo(() => {
        if (isMultipleAnswers) {
            return (
                <Fragment>
                    <div className={classes.buttonsWrapper}>
                        <div className={classes.button}>
                            <ImplicitBiasMultipleAnswers
                                answer={leftAnswers}
                            />
                        </div>
                        <div className={classes.button}>
                            <ImplicitBiasMultipleAnswers
                                answer={rightAnswers}
                            />
                        </div>
                    </div>
                </Fragment>
            )
        }
        return (
            <Fragment>
                <div className={classes.buttonsWrapper}>
                    <div className={classes.button}>
                        <ImplicitBiasSingleAnswer
                            answer={leftAnswers}
                        />
                    </div>
                    <div className={classes.button}>
                        <ImplicitBiasSingleAnswer
                            answer={rightAnswers}
                        />
                    </div>
                </div>
            </Fragment>
        )
    }, [isMultipleAnswers, leftAnswers, rightAnswers, classes])
}

export default ImplicitBiasAnswerHeader;