import React from 'react';
import Mustache from 'mustache';
import { Grid, makeStyles } from '@material-ui/core';
import TwitterIcon from '@material-ui/icons/Twitter';
import EmailIcon from '@material-ui/icons/Email';
import { RouteComponentProps } from '@reach/router';
import { useSelector } from 'react-redux';
import { getCurrentSurveyId, getSurveyResults } from 'src/state/features/survey/surveySelector';
import ContentfulRichText, { ContentfulRichTextType } from 'src/contentful/contentful-rich-text';

import { ContentfulSurveyForResultsQuery, ContentfulImplicitAssociationAssessment } from 'types/graphql-types';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import { Paths } from 'src/utilities/constants';
import { CustomLayout } from 'src/components/layout';
import { IHeaderProps, headerTypes, IFooterProps } from 'src/components/layout/custom-layout';
import ImplicitBiasResult, { iatScoreToText } from './components/implicit-bias-result';
import _ from 'lodash';
import { isUserFree } from 'src/state/features/user/userSelector';
import { StyledButton } from 'src/components/buttons';
import { getLanguage } from 'src/state/features/ui/uiSelector';

/**
 * Weight Bias Completed Page
 * This is the last step of the weight bias survey
 * 
 */

const useStyles = makeStyles(theme => ({
  shareHeading: {
    margin: "1.25em 0 0 0",
  },
  shareButton: {
    display: "inline-flex",
    width: "2.875em",
    height: "2.875em",
    minWidth: "0",
    marginLeft: "0.625rem",
    marginBottom: "-0.25rem",
    textAlign: "center",
    alignItems: "center",
    textDecoration: "none",
    backgroundColor: theme.palette.primary.main,
    border: "0.125rem solid " + theme.palette.primary.main,
    borderRadius: "100%",

    '&:hover, &:focus': {
      backgroundColor: theme.palette.secondary.light,

      '& .MuiSvgIcon-root': {
        color: theme.palette.primary.main,
      }
    }
  },
  startIcon: {
    margin: 0,
  },
  icon: {
    color: "#fff",
    fontSize: "2rem !important",
    margin: "0 auto"
  },
  saveHeading: {
    color: theme.palette.primary.main,
    fontFamily: "'Open Sans', Helvetica, Arial, sans-serif",
    fontSize: "1.625rem",
    fontWeight: 300,
    borderBottom: "0.0625rem solid #000",
  },
  font: {
    fontFamily: "'Roboto Condensed'",
    fontSize: "1.1875rem",

    '& h3': {
      margin: "1.5em 0",
      fontFamily: "Roboto",
      fontSize: "inherit",
    },
    '& p': {
      fontFamily: "inherit",
      fontSize: "inherit",
    }
  },
  divider: {
    width: "100%",
    height: "0.0625rem",
    margin: "1.25rem 0 0 0",
    border: "none",
    background: "#707070",
  },
  linkStyle: {
    color: theme.palette.primary.main,
  },
  richTextContent: {
    wordBreak: "break-word"
  },
  resultsContainer: {
    padding: theme.spacing(3),
    fontFamily: "Roboto",
    fontSize: "1.1875rem",
    lineHeight: 1.2,
    backgroundColor: "#F5F5F5",
  },
  resultsHeading: {
    margin: "0 0 0.625rem 0",
    fontFamily: "'Open Sans'",
    fontSize: "1.625rem",
    fontWeight: 300,
    lineHeight: 1.2,
  }

}))
interface IProps extends RouteComponentProps {
}

const SurveyResults: React.FC<IProps> = ({ }) => {
  const surveyId = useSelector(getCurrentSurveyId);
  const lang = useSelector(getLanguage);
  const rawData = useStaticQuery<ContentfulSurveyForResultsQuery>(graphql`
    query ContentfulSurveyForResults {
      allContentfulCourse {
        edges {
          node {
            node_locale
            id
            contentful_id
            slug
          }
        }
      }
      allContentfulSurvey {
        edges {
          node {
            node_locale
            id
            title
            slug
            linkedCourseId
            takeCourseButtonLabel
            shareMessageText
            shareEmailSubject
            shareEmailText
            labelForReturnToTests
            shareTwitterUrl
            shareTwitterText
            surveyEndTitle
            surveyEndContent {
              raw
              references {
                __typename
                id
                chartData {
                  id
                  data {
                    color
                    label
                  }
                }
                contentful_id
              }
            }
            surveyModules {
              id
              name
              content {
                ... on ContentfulImplicitAssociationAssessment {
                    ...implicitBiasAssessmentFragment
                }
                ... on ContentfulLikertAssessment {
                    ...likertFragment
                }
                ... on ContentfulMultipleChoiceQuestion {
                    ...multipleChoiceFragment
                }
              }
            }
          }
        }
      }
    }
  `);

  const fullData = rawData.allContentfulSurvey.edges;
  const data = fullData.find((x: any) => x?.node?.node_locale === lang && x?.node?.id === surveyId)?.node || fullData.find((x: any) => x?.node?.id === surveyId)?.node;

  const fullCourseData = rawData.allContentfulCourse.edges;
  const courseData = fullCourseData.find((x: any) => x?.node?.node_locale === lang && x?.node?.contentful_id === data?.linkedCourseId)?.node || fullCourseData.find((x: any) => x?.node?.contentful_id === data?.linkedCourseId)?.node;
  
  const classes = useStyles();
  const isFreeUser = useSelector(isUserFree);
  const results = useSelector(getSurveyResults);

  // const iat = _(data?.surveyModules).flatMap('content').find({ __typename: "ContentfulImplicitAssociationAssessment" })
  const iats = data?.surveyModules?.filter(x => x?.content?.find(y => y?.__typename === "ContentfulImplicitAssociationAssessment"))!;
  const iat = iats[0]?.content!;

  const {
    intensity,
    prefForGroup,
    prefAgainstGroup,
  } = iatScoreToText(iat[0]! as unknown as ContentfulImplicitAssociationAssessment, results?.implicitBiasScore as number, results?.implicitBiasInterpretation as number)

  const mustacheHash = {
    intensity: intensity,
    pref_for_group: prefForGroup,
    pref_against_group: prefAgainstGroup
  }

  function getEmailLink() {
    const template = Mustache.render(data?.shareEmailText as string, mustacheHash);
    const encoded = encodeURIComponent(template);
    const mailToLink = `mailto:?subject=${Mustache.render(data?.shareEmailSubject as string, mustacheHash)}!&body=${encoded}`
    return mailToLink;
  }

  function getTwitterLink() {
    const template = Mustache.render(data?.shareTwitterText as string, mustacheHash);
    const encoded = encodeURIComponent(template);
    const twitterLink = `https://twitter.com/share?url=${Mustache.render(data?.shareTwitterUrl as string, mustacheHash)}&text=${encoded}`;
    return twitterLink;
  }

  const mailToLink = getEmailLink();
  const twitterLink = getTwitterLink();

  async function saveResults() {
    navigate(Paths.SELECTION);
  }

  function onCourseNavigate() {
    if (courseData?.slug) {
      navigate(`${Paths.COURSE}/${courseData?.slug}`)
    }
  }

  const headerProps: IHeaderProps = {
    type: headerTypes.contentPagination,
    title: data?.surveyEndTitle || "",
    step: 0,
    allSteps: 0
  }
  const footerProps: IFooterProps = {
    allowSkip: false
  }
  return (
    <CustomLayout
      headerProps={headerProps}
      footerProps={footerProps}
    >
      <div className={classes.font}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className={classes.shareHeading}>
              {data?.shareMessageText as string}
              <a href={twitterLink} target="_blank" className={classes.shareButton} ><TwitterIcon className={classes.icon} /></a>
              <a href={mailToLink} target="_blank" className={classes.shareButton}> <EmailIcon className={classes.icon} /></a>
            </div>
          </Grid>
          <Grid item xs={12}>
            <ImplicitBiasResult
              score={results?.implicitBiasScore as number}
              intensity={results?.implicitBiasInterpretation as number}
              iatId={iat[0]?.id!}
            ></ImplicitBiasResult>
          </Grid>

          {results &&
            <Grid item xs={12} className={classes.richTextContent}>
              <ContentfulRichText {...data?.surveyEndContent as unknown as ContentfulRichTextType} />
            </Grid>
          }

        </Grid>
      </div>
    </CustomLayout>
  )
}


export default SurveyResults;