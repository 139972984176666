import React, { useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { navigate, RouteComponentProps, useLocation } from "@reach/router"
import { useDispatch, useSelector } from "react-redux"
import { startSurveyAction } from "src/state/features/survey/surveySlice"
import {
  resetHeaderVisibility,
  startSurvey,
} from "src/state/features/ui/uiSlice"
import CustomLayout, {
  headerTypes,
  IHeaderProps,
} from "src/components/layout/custom-layout"
import { Checkbox, FormControlLabel, Grid, makeStyles } from "@material-ui/core"
import ContentfulRichText, {
  ContentfulRichTextType,
} from "src/contentful/contentful-rich-text"
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined"
import { ButtonType } from "src/components/buttons/styled-button"
import { getLanguage } from "src/state/features/ui/uiSelector"
import {
  getUserPackages,
  getUserPackagesBySchedule,
  setScheduleId,
  setUserToken,
} from "src/state/features/user/userSlice"

const useStyles = makeStyles((theme) => ({
  surveyRoot: {
    // flexGrow: 1,
    // margin: 16,
  },
  container: {
    // height: "80vh"
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  richTextContent: {
    wordBreak: "break-word",
  },
  input: {
    margin: "2em 0",
  },
  continueContainer: {
    width: "100%",
    margin: "0 auto",
    textAlign: "center",
  },
  linkColor: {
    color: theme.palette.primary.main,
  },
  labelWrapper: {
    alignItems: "flex-start",
    margin: 0,
    padding: 0,
    border: "none",
  },
  label: {
    paddingTop: "4px",
    fontSize: "inherit",
    textAlign: "left",
    color: theme.palette.text.primary,
  },
  button: {
    minWidth: "13.75rem",
    margin: "1.875rem auto 1.25rem auto",
    textTransform: "none",
  },
  link: {
    display: "inline-block",
    marginBottom: "2.5rem",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    maxWidth: "20rem",
    margin: "auto",
    padding: "2em",
    textAlign: "center",
    backgroundColor: "#fff",
    borderRadius: "1rem",
    boxShadow: "0 0 0.5em rgba(0,0,0,0.5)",
    transform: "translate(-50%, -50%)",
  },
  modalHeading: {
    margin: "0 0 1em 0",
    fontFamily: "'Roboto Condensed'",
    fontSize: "1.1875rem",
    fontWeight: 400,
  },
  modalButton: {
    margin: "2em auto 0 auto",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  modalLink: {
    padding: "0.2rem",
    margin: "0.1rem",
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    textDecoration: "none",
    width: "60%",
    height: "50%",
  },
  modalInput: {
    padding: "0.5rem",
    width: "80%",
    height: "50%",
  },
}))

interface IProps extends RouteComponentProps {
  surveySlug?: string
}

const Survey = ({ surveySlug }: IProps) => {
  const lang = useSelector(getLanguage)
  const fullData = useStaticQuery<any>(graphql`
    query ContentfulSurvey {
      allContentfulSurvey {
        edges {
          node {
            id
            slug
            node_locale
            surveyStartContent {
              raw
            }
            surveyStartCheckboxContent {
              raw
            }
          }
        }
      }
    }
  `).allContentfulSurvey.edges
  const data =
    fullData.find(
      (x: any) => x?.node?.node_locale === lang && x?.node?.slug === surveySlug
    )?.node || fullData.find((x: any) => x?.node?.slug === surveySlug)?.node
  const surveyId = data?.id

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(resetHeaderVisibility())
  }, [])

  const classes = useStyles()
  const navigation = useLocation()
  const [privacyPolicyAccepted, setAccepted] = React.useState(false)

  useEffect(() => {
    async function getToken() {
      const searchParams = new URLSearchParams(window.location.search)
      const token = searchParams.get("code")
      const scheduleId = searchParams.get("schedule")

      if (token != null) {
        await dispatch(setUserToken(token))
        await dispatch(getUserPackages(token))
        return
      }

      if (scheduleId != null) {
        await dispatch(setScheduleId(scheduleId))
        await dispatch(getUserPackagesBySchedule(scheduleId))
      }
    }
    getToken()
  }, [])

  function onStartSurvey() {
    dispatch(startSurveyAction(surveyId))
    dispatch(startSurvey())
    navigate(`${navigation.pathname}/${surveyId}`)
  }

  const footerProps = {
    submitButton: {
      onClickHandler: onStartSurvey,
      type: ButtonType.CONTINUE,
      isDisabled: !privacyPolicyAccepted,
    },
    allowSkip: false,
  }

  const headerProps: IHeaderProps = {
    type: headerTypes.logo,
  }

  return (
    <CustomLayout headerProps={headerProps} footerProps={footerProps}>
      <Grid item xs={12} className={classes.richTextContent}>
        <ContentfulRichText
          {...(data?.surveyStartContent as ContentfulRichTextType)}
        />

        <div className={classes.continueContainer}>
          <FormControlLabel
            classes={{
              root: classes.labelWrapper,
              label: classes.label,
            }}
            control={
              <Checkbox
                checked={privacyPolicyAccepted}
                onChange={(e) => setAccepted(e.target.checked)}
                name="privacyPolicyAccepted"
                checkedIcon={
                  <CheckBoxOutlinedIcon className={classes.linkColor} />
                }
              />
            }
            label={
              <ContentfulRichText
                {...(data?.surveyStartCheckboxContent as ContentfulRichTextType)}
              />
            }
          />
        </div>
      </Grid>
    </CustomLayout>
  )
}

export default Survey
